<template>
  <v-card class="d-inline-flex align-center font-weight-bold justify-center px-4 py-1 fill-height primary rounded-pill white--text" light outlined @click.prevent="loadingStep = 0, dialogConfirmExport = true">
    <v-icon small class="mr-1" color="white">
      mdi-file-export
    </v-icon>
    Export
    <v-dialog
      v-model="dialogConfirmExport"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title class="justify-center">
          Export Data
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4" style="max-height: 340px;">
          <div v-if="loadingStep === 0">
            <v-card class="mt-4 mb-4 pa-4 d-flex fill-width rounded-lg font-weight-bold justify-center c-pointer text-center" outlined @click.prevent="ProcessExport()">
              Process Export
            </v-card>
            <!-- <v-card class="mb-4 pa-4 d-flex fill-width rounded-lg font-weight-bold justify-center c-pointer" outlined @click.prevent="ProcessExport(true)">
              Export Semua Data
            </v-card> -->
          </div>
          <div v-if="loadingStep > 0 && loadingStep < 4" class="d-flex flex-column align-center justify-center fill-width py-8">
            <v-progress-circular
              :size="40"
              :width="3"
              color="primary"
              indeterminate
            />
            <div class="pt-1">
              {{ loadingStep === 2 ? 'Request data' : 'Preparing data' }} ...
            </div>
          </div>
          <div v-else-if="loadingStep === 4">
            <xlsx-workbook>
              <xlsx-sheet
                v-for="sheet in sheets"
                :key="sheet.name"
                :sheet-name="sheet.name"
                :collection="sheet.data"
              />
              <xlsx-download :filename="'abclogistic-data-' + (new Date()).toISOString() + '.xlsx'" :download="DownloadData">
                <v-btn depressed block color="primary" class="white--text text-capitalize rounded-pill" @click.prevent="DownloadData">
                  Download Data
                </v-btn>
              </xlsx-download>
            </xlsx-workbook>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center">
          <v-btn
            color="grey darken-1"
            outlined
            depressed
            small
            class="rounded-pill text-capitalize px-8"
            @click="dialogConfirmExport = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'
export default {
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  props: {
    province: {
      type: Number,
      default: 0
    },
    statusProgress: {
      type: Number,
      default: 0
    },
    customerProjectSelectedID: {}
  },
  data: () => ({
    dialogConfirmExport: false,
    loadingStep: 0,
    sheets: []
  }),
  methods: {
    DownloadData () {
      this.dialogConfirmExport = false
    },
    async ProcessExport () {
      this.loadingStep = 0

      this.loadingStep = 1
      const pageAndLimit = '&page=1&limit=100000'
      this.$store.dispatch('logistic/REPORT_SHIPPING_GET', (`?pid=${this.customerProjectSelectedID}&province=` + (this.province || 0)) + ('&status_progress=' + (this.statusProgress || 0) + pageAndLimit))
        .then((res) => {
          if (res.status) {
            try {
              const data = res.data.data.data || []
              if (data.length) {
                this.ParsingData(data)
              } else {
                this.$store.dispatch('TOAST', { show: true, message: 'No data to export!' })
                this.loadingStep = 0
              }
            } catch {
              this.$store.dispatch('TOAST', { show: true, message: 'Export failed!' })
              this.loadingStep = 0
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
            this.loadingStep = 0
          }

          // if (res.status) {
          //   this.shippingGetData.data = res.data.data.data
          //   this.shippingGetData.total = parseInt(res.data.data.total) || 0
          // } else {
          //   this.shippingGetData.data = []
          //   this.shippingGetData.total = 0
          // }
        })
    },
    ParsingData (data) {
      this.loadingStep = 2
      this.sheets = []
      for (let a = 0; a < data.length; a++) {
        const obj = Object.assign({}, data[a])
        const plan = obj.manifest_plan
        const PlatSplit = plan.split('-|-')
        const planData = PlatSplit.length > 5 ? {
          id: parseInt(PlatSplit[0]) || 0,
          status: parseInt(PlatSplit[1]) || 0,
          recipient_name: PlatSplit[5] || '',
          recipient_relation: PlatSplit[6] || '',
          recipient_imgs: PlatSplit[7] || '',
          recipient_notes: PlatSplit[8] || '',
          recipient_time: PlatSplit[9] || ''
        } : {}
        const cityAddress = obj.shipping_destination_city_name + ' (' + obj.shipping_destination_city_type + '), ' + obj.shipping_destination_province_name
        const o = {
          id: obj.id,
          ttk: obj.ttk,
          recipient_status: parseInt(planData.status) ? 'Delivered' : 'On Delivery',
          shipping_name: obj.shipping_name || '',
          address: obj.shipping_address || '',
          address_city: cityAddress || '',
          recipient_id: planData.id,
          recipient_name: planData.recipient_name,
          recipient_relation: planData.recipient_relation,
          recipient_notes: planData.recipient_notes,
          recipient_images_count: planData.recipient_imgs ? this.$StrToArray(planData.recipient_imgs).length : 0,
          recipient_images: planData.recipient_imgs,
          recipient_time: planData.recipient_time
        }
        const kotakab = obj.shipping_destination_city_type === 'Kota' ? (obj.shipping_destination_city_type + ' ') : ''
        const sName = kotakab + '' + obj.shipping_destination_city_name + ', ' + obj.shipping_destination_province_name
        const sheetName = parseInt(this.province) ? (sName || '').substring(0, 30) : 'All Provinces'
        const findIndex = this.sheets.findIndex(r => r.name === sheetName)
        if (findIndex > -1) {
          this.sheets[findIndex].data.push(o)
        } else {
          this.sheets.push({
            name: sheetName,
            data: [o]
          })
        }
      }
      this.loadingStep = 3
      setTimeout(() => {
        this.loadingStep = 4
      }, 500)
    }
  }
}
</script>
